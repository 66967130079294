import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProductLayout from "../../components/layouts/product_layout"
import {
    ProductButtons,
    ProductSubHeader,
    ProductParagraph,
    ProductScreenShot,
    ProductBullets,
    ResourceTeaser,
    ProductTestimonials,
} from "../../components/shared"
import ProductDemoForm from "../../components/products/product_demo_form"

export default function CampaignLaunchpad({ location }) {
    const data = useStaticQuery(graphql`
        query {
            productsYaml(name: { eq: "campaign-launchpad" }) {
                title
                description
                app_cloud_link
                copy {
                    tagline
                    header2
                    paragraph1
                    paragraph2
                    paragraph3
                    bullets {
                        header
                        value
                        description
                    }
                    testimonials {
                        quote
                        client
                    }
                    demo_message
                }
                rating
                ratingCount
            }
            productLogo: file(
                relativePath: {
                    eq: "images/products/campaign-launchpad/logo.png"
                }
            ) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            screenShots: allFile(
                filter: {
                    relativeDirectory: {
                        eq: "images/products/campaign-launchpad/screenshots"
                    }
                }
            ) {
                nodes {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            podcastThumbnail: file(
                relativePath: {
                    eq: "images/resources/podcasts/demandgen-radio-kevin-cassidy-campaign-launchpad.jpg"
                }
            ) {
                childImageSharp {
                    fixed(height: 220) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const {
        productsYaml: {
            title,
            description,
            app_cloud_link,
            copy: {
                tagline,
                header2,
                paragraph1,
                paragraph2,
                paragraph3,
                bullets,
                testimonials,
                demo_message,
            },
            rating,
            ratingCount,
        },
        productLogo: {
            childImageSharp: { fixed: logoFixed },
        },
        screenShots: { nodes: screenShots },
        podcastThumbnail: {
            childImageSharp: { fixed: podcastThumbnailFixed },
        },
    } = data

    return (
        <ProductLayout
            title={title}
            description={description}
            location={location}
            tagline={tagline}
            logo={logoFixed}
            rating={rating}
            ratingCount={ratingCount}
        >
            <div className="mt-8">
                <ProductParagraph content={paragraph1} />
            </div>

            <ProductScreenShot fluid={screenShots[1].childImageSharp.fluid} />

            <ProductButtons appCloudLink={app_cloud_link} />

            <ResourceTeaser
                thumbnail={podcastThumbnailFixed}
                content={`Check out the DemandGen Radio episode featuring Kevin Cassidy, Head of Global Marketing Automation at Sage, who talks about how Campaign Launchpad™ allows to centralize marketing operations functions while decentralizing responsibilities.`}
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9kZW1hbmRnZW5yYWRpby5jb20vZmVlZC54bWw/episode/ZGVtYW5kZ2VuLnBvZGJlYW4uY29tLzgzLWNlbnRyYWxpemVkLW9yLWRlY2VudHJhbGl6ZWQtaG93LXRvLXN0cnVjdHVyZS15b3VyLWRlbWFuZC1jZW50ZXItMDdhZTcxMjgyMjlhMWU0NDJmMmVjYjU3MzhmYmJlMzg?sa=X&ved=0CAUQkfYCahcKEwig2omSuuPxAhUAAAAAHQAAAAAQAQ"
                buttonText="Listen Now"
                target="_blank"
            />

            <div className="mt-12">
                <ProductSubHeader content={header2} />
                <ProductParagraph content={paragraph2} />
                <ProductParagraph content={paragraph3} />
            </div>

            <ProductScreenShot fluid={screenShots[2].childImageSharp.fluid} />

            <ProductBullets bullets={bullets} />

            <ProductScreenShot fluid={screenShots[0].childImageSharp.fluid} />

            <ProductTestimonials testimonials={testimonials} />

            <ProductDemoForm formMessage={demo_message} />
        </ProductLayout>
    )
}
