import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../../components/footer"
import ProductNav from "../../components/products/product_nav"
import SEO, { AppStructuredData } from "../../components/seo"
import FontLoader from "../font_loader"
import { ProductHeading } from "../shared"

export default function ProductLayout({
    location,
    title,
    description,
    logo,
    tagline,
    children,
    rating,
    ratingCount
}) {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "images/logo-dark.png" }) {
                childImageSharp {
                    fixed(height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const {src, width, height} = logo

    return (
        <>
            <FontLoader preload={["montserrat-500", "raleway-400"]} />

            <SEO title={title} description={description} location={location} image={{src, width, height}}>
                <AppStructuredData name={title} description={description} rating={rating} ratingCount={ratingCount} />
            </SEO>

            <div className="py-4 bg-blue-dark">
                <div className="py-2 bg-white">
                    <div className="flex flex-wrap">
                        <div className="w-full mt-1 text-center lg:mt-0 lg:w-1/2 lg:text-left lg:pl-10">
                            <Link to="/">
                                <Img fixed={data.logo.childImageSharp.fixed} alt="DemandByte Home Page"/>
                            </Link>
                        </div>

                        <div className="w-full mt-8 text-center lg:w-1/2 lg:text-right lg:pr-10 lg:mt-2">
                            <ProductNav />
                        </div>
                    </div>

                    <div className="container max-w-4xl p-4 mx-auto lg:pt-0">
                        <ProductHeading
                            title={title}
                            description={tagline}
                            logoFixed={logo}
                        />

                        {children}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}
