import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useLocation } from "@reach/router"

const ProductNavIcon = ({ productName, productTitle, iconFixed }) => {
    return (
        <Link to={`/products/${productName}`} className="mx-2 hover:opacity-50">
            <Img fixed={iconFixed} className="" alt={productTitle} />
        </Link>
    )
}

const ProductNav = () => {
    const data = useStaticQuery(graphql`
        {
            icons: allFile(
                filter: {
                    relativeDirectory: { eq: "images/icons/products/nav" }
                    extension: { in: ["png"] }
                }
                sort: { fields: [name], order: [ASC] }
            ) {
                nodes {
                    name
                    childImageSharp {
                        fixed(width: 40, height: 40) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `)

    const location = useLocation()
    const currentProduct = location.pathname.split("/")[2]

    const productNavIcons = []
    for (let i = 0; i < data.icons.nodes.length; i = i + 2) {
        const productName = data.icons.nodes[i].name.replace(/-red|-blue/, "")
        // Convert the underscored name into title case.
        const productTitle = productName.replace(/-/," ").replace(/(^|\s)\S/g, (t) =>
            t.toUpperCase()
        )
        const iconFixed =
            data.icons.nodes[productName.match(currentProduct) ? i + 1 : i]
                .childImageSharp.fixed

        productNavIcons.push(
            <ProductNavIcon
                productName={productName}
                key={productName}
                productTitle={productTitle}
                iconFixed={iconFixed}
            />
        )
    }

    return <div>{productNavIcons}</div>
}

export default ProductNav
