import React from "react"
import { useLocation } from "@reach/router"
import { ProductParagraph } from "../shared"
import ContactUsFormWrapper from "../contact_us_form_wrapper"

const ProductDemoForm = ({ formMessage }) => {

  const location = useLocation()
  const currentProduct = location.pathname.split("/")[2]

  return (
    <div className="text-left lg:px-32" id="demo">
      <h3 className="mt-12 text-xl font-bold font-headers text-red-light">
        Request a Demo
      </h3>
      <ContactUsFormWrapper
        formName={`${currentProduct}-demo`}
        theme="dark"
        header={<ProductParagraph content={formMessage} />}
        submitButtonText='Request a Demo'
        submittedMessage={
          <div className="min-h-xs lg:min-h-md">
            <ProductParagraph
              content={
                "Thanks for contacting us! Someone will get back to you within 24 hours."
              }
            />
          </div>
        }
      />
    </div>
  )
}

export default ProductDemoForm
